import { render, staticRenderFns } from "./picture_management.vue?vue&type=template&id=6486b386&scoped=true&"
import script from "./picture_management.vue?vue&type=script&lang=js&"
export * from "./picture_management.vue?vue&type=script&lang=js&"
import style0 from "./picture_management.vue?vue&type=style&index=0&lang=css&"
import style1 from "./picture_management.vue?vue&type=style&index=1&id=6486b386&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6486b386",
  null
  
)

export default component.exports