<template>
    <div class="home">
    <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="应用位置：">
            <el-select style="width:200px;"  v-model="status" placeholder="请选择">
                  <el-option
                      v-for="(ot,i) in listtype"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.value">
                    </el-option>
            </el-select>
            </el-form-item>
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.search_data" style="width:400px" placeholder="请输入关键字"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 
      <div class="left">
        <el-button  @click="add()" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加</el-button>
      </div>
      <div class="pt20"></div>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
        @selection-change="handleSelectionChange"
         >
       <el-table-column type="selection" width="55" align="center"/>
        <el-table-column
          prop="id"
          width="200"
          label="序号">
        </el-table-column>
        <el-table-column
          prop="item_type"
          width="200"
          label="应用位置">
          <template  slot-scope="scope">
            {{scope.row.item_type | fiftlertype}}
         </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="选项名称">
        </el-table-column>
        <el-table-column
          prop="max"
          label="数量限制">
        </el-table-column>
        <el-table-column
          prop="images"
          label="示例图">
          <template  slot-scope="scope">
            {{scope.row.images ? '有' : '没有'}}
         </template>
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间">
          <template  slot-scope="scope">
            {{scope.row.create_time | formatDate}}
         </template>
        </el-table-column>
        <el-table-column
          prop="operator_name"
          label="操作人员">
        </el-table-column>
        <el-table-column
          label="操作">
          <template  slot-scope="scope">
            <el-button @click='bianji(scope.row.id)' size="mini" type="info" plain>编辑</el-button>
            <el-button @click='dd(scope.row.id)' size="mini" type="info" plain>删除</el-button>
         </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          layout="total,prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <el-dialog
        title="编辑"
        :visible.sync="eddbankshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form ref="registerdata" :model="registerdata" label-width="120px">
            <el-form-item label="应用位置" prop="name">
              <el-select style="width:200px;"  v-model="registerdata.item_type" placeholder="请选择">
                  <el-option
                      v-for="(ot,i) in listtype"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.value">
                    </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="类型" prop="style_type">
              <el-radio-group v-model="registerdata.style_type" required>
                <el-radio :label="1">身份证</el-radio>
                <el-radio :label="2">其他附件</el-radio>
              </el-radio-group>
            </el-form-item>
            
            <el-form-item label="选项名称" prop="name">
              <el-input v-model="registerdata.name" placeholder="请输入选项名称" clearable required></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
              <el-input v-model.number="registerdata.sort" placeholder="请输入排序" clearable required></el-input>
            </el-form-item>
            <el-form-item label="最大上传数量" prop="max">
              <el-input v-model.number="registerdata.max" placeholder="请输入最大上传数量" clearable required></el-input>
            </el-form-item>
            <el-form-item v-show="fileList3shows" label="示例图" prop="max">
              <el-upload
                  class="upload-demo"
                  action="#"
                  list-type="picture-card"
                  :http-request="httpRequest3"
                  :on-remove="handleRemove3"
                  multiple
                  :limit="limit"
                  :on-exceed="handleExceed"
                  :file-list="fileList3">
                   <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item v-show="fileList3shows"  label="示例图(英文)" prop="max">
              <el-upload
                  class="upload-demo"
                  action="#"
                  list-type="picture-card"
                  :http-request="httpRequest4"
                  :on-remove="handleRemove4"
                  multiple
                  :limit="limit"
                  :on-exceed="handleExceed"
                  :file-list="fileList4">
                   <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="submitregisterdata">保存</el-button>
              <el-button @click="eddbankshow = false">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
      <el-dialog
        title="添加"
        :visible.sync="addbankshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div>
          <el-form ref="form" :model="form" label-width="120px">
            <el-form-item label="应用位置" prop="item_type">
              <el-select style="width:200px;"  v-model="form.item_type" placeholder="请选择">
                  <el-option
                      v-for="(ot,i) in listtype"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.value">
                    </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="类型" prop="style_type">
              <el-radio-group v-model="form.style_type" required>
                <el-radio label="1">身份证</el-radio>
                <el-radio label="2">其他附件</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="选项名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入选项名称" clearable required></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
              <el-input v-model.number="form.sort" placeholder="请输入排序" clearable required></el-input>
            </el-form-item>
            <el-form-item label="最大上传数量" prop="max">
              <el-input v-model.number="form.max" placeholder="请输入最大上传数量" clearable required></el-input>
            </el-form-item>
            <el-form-item label="示例图" prop="max">
              <el-upload
                  class="upload-demo"
                  action="#"
                  list-type="picture-card"
                  :http-request="httpRequest"
                  :on-remove="handleRemove"
                  multiple
                  :limit="limit"
                  :on-exceed="handleExceed"
                  :file-list="fileList1">
                   <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="示例图(英文)" prop="max">
              <el-upload
                  class="upload-demo"
                  action="#"
                  list-type="picture-card"
                  :http-request="httpRequest2"
                  :on-remove="handleRemove2"
                  multiple
                  :limit="limit"
                  :on-exceed="handleExceed"
                  :file-list="fileList2">
                   <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="submitForm">提交</el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src   
import axios from 'axios'
import config from '../../lib/config'
let vue;
export default {
  name: 'Home',
  data(){
    return {
      form: {
        item_type: '',
        style_type: '',
        name: '',
        sort: '',
        max: '',
        images: [],
        en_images: [],
      },
      registerdata:{
        item_type: '',
        style_type: '',
        name: '',
        sort: '',
        max: '',
        images: [],
        en_images: [],
      },
      fileList3shows:false,
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      limit:5,
      loading:true,
      total:0,
      per_page: 0,
      current_page:1,
      addbankshow:false,
      eddbankshow:false,
      listtype:[],
      tableData: [],
      payChannel:[],//支付渠道
      formInline: {
         page:1,//页码 0不分页
         search_data:'',//检索内容
         item_type:'',//选项名称
      },
      status:3,//应用位置
      timevalue:'',//交易日期
      // 选中数组
      ghs: [],
      nums:[],
     //选中的记录数量
     initdata:{
         page:1,//页码 0不分页
         search_data:'',//检索内容
         item_type:3,// 选项名称
     },
     selectedNum:0,
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  filters: {
    formatDate(timestamp) {
      const date = new Date(timestamp*1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);
      const second = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    },fiftlertype(type) {
      for(let i in vue.listtype){
        if(vue.listtype[i].value == type){
          return vue.listtype[i].name;
        }
      }
      return '';
    }
  },
  methods: {
      handleExceed(files, fileList) {
          this.$message.warning('当前限制选择 '+this.limit+' 个文件，本次选择了 '+files.length+' 个文件，共选择了'+ (files.length + fileList.length)+' 个文件');
      },
      handleRemove(file) {
        let list = this.fileList1;
        for(let i in list){
          if(list[i].name == file.name){
            list.splice(i,1);
          }
        }
      },
      handleRemove2(file) {
        let list = this.fileList2;
        for(let i in list){
          if(list[i].name == file.name){
            list.splice(i,1);
          }
        }
      },
      handleRemove3(file) {
        let list = this.fileList3;
        for(let i in list){
          if(list[i].name == file.name){
            list.splice(i,1);
          }
        }
      },
      handleRemove4(file) {
        let list = this.fileList4;
        for(let i in list){
          if(list[i].name == file.name){
            list.splice(i,1);
          }
        }
      },
      httpRequest(data) {
            let file = data.file;
            const isImg = file.size / 1024 / 1024 < 2
            if (!isImg) {
                 return 
            }
            const isType = file.type === "image/png"
            const isType2 = file.type === "image/jpeg"
            if (!isType && !isType2) {
                 return 
            }
          let _this = this;
        let formdata = new FormData();
        formdata.append("file",file,file.name)
          axios.post(config.file_upload,formdata,{headers:{'Content-Type': 'multipart/form-data'}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.fileList1.push({
                name:response.data.data.images,
               // url:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fsafe-img.xhscdn.com%2Fbw1%2Fae175b96-62b4-4c48-ae36-a278a8a27e2c%3FimageView2%2F2%2Fw%2F1080%2Fformat%2Fjpg&refer=http%3A%2F%2Fsafe-img.xhscdn.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1692954820&t=f85e5dfc296675a4bf3e60d1b8362c12',
                url:response.data.data.images,
              });
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        },
      httpRequest2(data) {
            let file = data.file;
            const isImg = file.size / 1024 / 1024 < 2
            if (!isImg) {
                 return 
            }
            const isType = file.type === "image/png"
            const isType2 = file.type === "image/jpeg"
            if (!isType && !isType2) {
                 return 
            }
          let _this = this;
        let formdata = new FormData();
        formdata.append("file",file,file.name)
          axios.post(config.file_upload,formdata,{headers:{'Content-Type': 'multipart/form-data'}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.fileList2.push({
                name:response.data.data.images,
                url:response.data.data.images,
              });
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        },
      httpRequest3(data) {
            let file = data.file;
            const isImg = file.size / 1024 / 1024 < 2
            if (!isImg) {
                 return 
            }
            const isType = file.type === "image/png"
            const isType2 = file.type === "image/jpeg"
            if (!isType && !isType2) {
                 return 
            }
          let _this = this;
        let formdata = new FormData();
        formdata.append("file",file,file.name)
          axios.post(config.file_upload,formdata,{headers:{'Content-Type': 'multipart/form-data'}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.fileList3.push({
                name:file.name,
                url:response.data.data.images,
              });
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        },
      httpRequest4(data) {
            let file = data.file;
            const isImg = file.size / 1024 / 1024 < 2
            if (!isImg) {
                 return 
            }
            const isType = file.type === "image/png"
            const isType2 = file.type === "image/jpeg"
            if (!isType && !isType2) {
                 return 
            }
          let _this = this;
        let formdata = new FormData();
        formdata.append("file",file,file.name)
          axios.post(config.file_upload,formdata,{headers:{'Content-Type': 'multipart/form-data'}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.fileList4.push({
                name:file.name,
                url:response.data.data.images,
              });
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        },
      bianji(id){
        let _this = this;
        let data = {
          id:id
        }
        axios.get(config.image_type_detail,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.registerdata = response.data.data;
              _this.eddbankshow = true;
              let f3 = _this.registerdata.images ? _this.registerdata.images.split(',') : [];
              _this.fileList3 = [];
              _this.fileList3shows = false;
              for(let i in f3){
                _this.fileList3.push({
                  name:f3[i],
                  url:f3[i]
                }) 
              }
              setTimeout(()=>{
                _this.fileList3shows = true;
              },1000)
              let f4 = _this.registerdata.en_images ? _this.registerdata.en_images.split(',') : [];
              _this.fileList4 = [];
              for(let i in f4){
                _this.fileList4.push({
                  name:f4[i],
                  url:f4[i]
                }) 
              }
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      dd(id){
        let _this = this;
        this.$confirm('此操作将删除列, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.delete(config.image_type,{data:{id:id}})
          .then(response => {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.onSubmit('CHA');
            }else{
                _this.$message.error(response.data.message);
              }
          })
          .catch(error => {
            _this.$message.error(error);
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
       
      },
      submitForm() {// 手动编写非空验证代码
        let _this = this;
        let from = _this.form;
        let fileList1 = _this.fileList1;
        let fileList2 = _this.fileList2;
        let img = '',enimg = '';
        for(let i in fileList1){
            img += fileList1[i].url + ',';
        }
        for(let i in fileList2){
          enimg += fileList2[i].url + ',';
        }
        img = img.substring(0,img.length - 1);
        enimg = enimg.substring(0,enimg.length - 1);
        // if (from.item_type === '' || from.style_type === '' || !from.name || from.sort === '' || from.max === '' || !img || !enimg) {
        //   _this.$message.error('表单数据不能为空');
        //   return false;
        // }
        from.style_type = from.style_type ? from.style_type : 1;
        let jsondata = {
          item_type:from.item_type,
          style_type:from.style_type,
          name:from.name,
          sort:from.sort,
          max:from.max,
          images:img,
          en_images:enimg,
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            axios.put(config.image_type,jsondata)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.onSubmit('CHA');
                  _this.addbankshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });
      },
      submitregisterdata(){
        let _this = this;
        let from = _this.registerdata;
        let fileList3 = _this.fileList3;
        let fileList4 = _this.fileList4;
        let img = '',enimg = '';
        for(let i in fileList3){
            img += fileList3[i].url + ',';
        }
        for(let i in fileList4){
          enimg += fileList4[i].url + ',';
        }
        img = img.substring(0,img.length - 1);
        enimg = enimg.substring(0,enimg.length - 1);
        // if (from.item_type === '' || from.style_type === '' || !from.name || from.sort === '' || from.max === '' || !img || !enimg) {
        //   _this.$message.error('表单数据不能为空');
        //   return false;
        // }
        from.style_type = from.style_type ? from.style_type : 1;
        let jsondata = {
          id:from.id,
          item_type:from.item_type,
          style_type:from.style_type,
          name:from.name,
          sort:from.sort,
          max:from.max,
          images:img,
          en_images:enimg,
        }
        this.$refs.registerdata.validate(valid => {
          if (valid) {
            axios.post(config.image_type,jsondata)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.onSubmit('CHA');
                  _this.eddbankshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });

      },
      resetForm() {
        this.$refs.form.resetFields();
        this.fileList2 = [];
        this.fileList1 = [];
      },
      add(){
        this.addbankshow = true;
        this.$refs.form.resetFields();
        this.fileList2 = [];
        this.fileList1 = [];
      },
      exportlist(){

      },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        this.formInline.item_type = this.status == '' ? 1 : this.status;
        axios.get(config.image_type,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      handleSelect(key) {
        this.formInline.order_type = key;
        this.onSubmit('CHA');
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
      },typeIndex(index) {
        let _this = this; //处理分页数据的 index
        return (_this.formInline.page - 1) * 20 + index + 1;
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.id)
        this.nums = selection.map(item => item.num)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        axios.get(config.image_type,{params:this.initdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.selectitem_type,{})
        .then(function (response) {
            if(response.data.code == 200){
              _this.listtype = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  },
  beforeCreate(){
    vue = this;
  }
}
</script>
<style>

.v-modal{
  display: none;
}
</style>
<style scoped>
.left{
    width: 70px;
    text-align: left;
}
</style>